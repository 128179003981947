// THIS IS A GENERATED FILE. DO NOT EDIT THIS FILE. ENV_IS_dev DATE GENERATED: 1715289210131 
import { Config } from "./config.def";

export const config: Config = {
  appApiRoot: "https://api-dev.olliesports.com",
  firebaseWebConfig: {
  "apiKey": "AIzaSyCYn_SeIYWn1uLAIL3rf7ppYa1e4kAwAE4",
  "authDomain": "ollie-app-dev-9a26f.firebaseapp.com",
  "databaseURL": "https://ollie-app-dev-9a26f.firebaseio.com",
  "projectId": "ollie-app-dev-9a26f",
  "storageBucket": "ollie-app-dev-9a26f.appspot.com",
  "messagingSenderId": "235055918478",
  "appId": "1:235055918478:web:103e75621ca6913c"
},
  stripePublic: "pk_test_aPfEV5rTQCCrOLGZa1c4k5z4006YFI2wGb",
  olliePipeUrl: "https://pipe-dev.olliesports.com/pipe",
  olliePipeFallBackUrlFirestore: "https://firestore.googleapis.com/v1/projects/ollie-app-dev-9a26f/databases/(default)/documents/backupPipe",
  universalLinkHttpBase: "https://dev.ollsp.co"
};
