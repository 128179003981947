import React, { Suspense, FC, useEffect, useState, useLayoutEffect, ReactNode } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import GlobalStyles from "src/components/GlobalStyles";
import ScrollReset from "src/components/ScrollReset";
import GoogleAnalytics from "src/components/GoogleAnalytics";
import useSettings from "src/hooks/useSettings";
import { createTheme } from "src/theme";
import routes, { Routes } from "src/routes";
import { initApp } from "./services/init.service";
import { loadStripe } from "@stripe/stripe-js";
import { config } from "./config";
import { Elements } from "@stripe/react-stripe-js";
import { getQueryClient } from "@ollie-sports/react-bifrost";
import { Toaster } from "react-hot-toast";
import { QueryClientProvider } from "react-query";
import { RootDialogProvider } from "./services/rootDialog.service";
import { ImperativeModalProvider } from "./components/modals/imperativeModal";

import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const jss = create({ plugins: [...jssPreset().plugins] });
const history = createBrowserHistory();
const appInitProm = initApp();

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(config.stripePublic);

const App: FC = () => {
  const [appHasLoaded, setAppHasLoaded] = useState(false);
  useEffect(() => {
    appInitProm.then(() => {
      setAppHasLoaded(true);
    });
  }, []);

  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <QueryClientProvider client={getQueryClient() as any}>
      <Router history={history}>
        <div id="headlessui-portal-root"></div>
        <Toaster containerStyle={{ zIndex: 70 }} />
        <RootDialogProvider>
          <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <SnackbarProvider dense maxSnack={3}>
                  <ImperativeModalProvider>
                    <Elements stripe={stripePromise}>
                      <GlobalStyles />
                      <ScrollReset />
                      <GoogleAnalytics />
                      {appHasLoaded ? <Routes routes={routes} /> : null}
                    </Elements>
                  </ImperativeModalProvider>
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeProvider>
        </RootDialogProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
